import { createModel } from './model'
import { createCollection } from './collection'
import { merge } from 'lodash'


export function createResource(client, name, options = {}) {

  const resource = { name, options }

  function model(options = {}) {
    return createModel(client, resource.name, merge({}, resource.options, options))
  }

  function collection(options = {}) {
    return createCollection(client, resource.name, merge({}, resource.options, options))
  }

  function remove(id, options = {}) {
    return client.delete(name + '/' + (Array.isArray(id) ? id.join(',') : id), merge({}, resource.options, options))
  }

  function get(id, options = {}) {
    return client.get(name + '/' + (Array.isArray(id) ? id.join(',') : id), merge({}, resource.options, options))
  }

  function post(id, data, options = {}) {
    return client.post(name + '/' + (Array.isArray(id) ? id.join(',') : id), data, merge({}, resource.options, options))
  }

  return {
    model,
    collection,
    remove,
    delete: remove,
    get,
    post,
  }

}