import { createClient } from './client'
import { createUser } from './user'
import { createResource } from './resource'
import { utils } from './utils'
import { merge } from 'lodash'

export { utils } from './utils'

export function createApi(options = {}) {

  const user = createUser(createClient({
    baseURL: options.baseUrl,
  }), options)

  const client = createClient({
    baseURL: options.baseUrl,
  })

  const resources = options.resources || {}
  const debug = options.debug || false
  const itemsPerPage = options.itemsPerPage || 10
  const filters = options.filters || {}
  const pingUrl = options.pingUrl || '/users/ping'

  client.interceptors.request.use(config => {
    config.headers['Authorization'] = `Bearer ${user.state.accessToken}`
    return config
  }, error => Promise.reject(error))

  client.interceptors.response.use(response => response, error => {
    if (error && error.response && error.response.status === 401) {
      user.logout()
    }
    return Promise.reject(error)
  })

  if (options.ping) {
    setInterval(() => {
      if (user.state.isLoggedIn) {
        client.get(pingUrl).catch(user.logout)
      }
    }, options.ping)
  }

  function resource(name, options = {}) {
    return createResource(client, name, merge({}, { debug, itemsPerPage }, { filters }, resources[name] || {}, options))
  }

  return {
    user,
    client,
    resource,
    utils,
  }

}



