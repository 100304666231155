import { createApi } from '@/api'

const api = createApi({
  baseUrl: process.env.VUE_APP_API_URL,
  resources: {
    'offer-line-products': {
      primaryKey: ['offer_line_id'],
    },
  },
  ping: 30000,
})

export default api