import { reactive, computed, toRefs } from '@vue/composition-api'
import { createStorage } from './storage'


export function createUser(client, options = {}) {

  const storage = createStorage(options.storage || localStorage)
  const loginUrl = options.loginUrl || '/users/login'

  const state = reactive({
    accessToken: storage.getItem('accessToken'),
    identity: storage.getObject('identity') || {},
    isLoggingIn: false,
    isLoggedIn: computed(() => state.accessToken && state.identity.id),
  })

  function login(credentials) {

    state.isLoggingIn = true

    return client.post(loginUrl, credentials)
      .then(response => {

        storage.setItem('accessToken', response.data.access_token)
        storage.setObject('identity', response.data.user)

        state.accessToken = response.data.access_token
        state.identity = response.data.user

        return Promise.resolve(state.identity)
      })
      .catch(() => {

        storage.removeItem('accessToken')
        storage.removeItem('identity')

        state.accessToken = null
        state.identity = {}

        return Promise.reject()
      })
      .finally(() => {

        state.isLoggingIn = false
      })
  }

  function logout() {

    storage.removeItem('accessToken')
    storage.removeItem('identity')

    state.accessToken = null
    state.identity = {}

    return Promise.resolve()
  }

  function can(permission) {
    return (state.identity.permissions || []).includes(permission)
  }

  return {
    state,
    ...toRefs(state),
    login,
    logout,
    can,
  }

}