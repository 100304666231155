import { utils } from './utils'
import { isString, isArray, cloneDeep, merge } from 'lodash'

export const FILTER_IN = 'in'
export const FILTER_OUT = 'out'

const availableFieldsTypes = {
  'noop': { in: value => value, out: value => value },
  'float': { in: value => parseFloat(value), out: value => parseFloat(value) },
  'int': { in: value => parseInt(value), out: value => parseInt(value) },
  'json': { in: value => { try { return JSON.parse(value) } catch(err) { console.log(err) } return null }, out: value => JSON.stringify(value) },
}

function prepareFields(fields) {

  const prepareFields = {}

  Object.keys(fields).forEach(name => {

    if (isString(fields[name])) {

      let parts = fields[name].split(':')
      let typeParts = parts[0].split(',')
      let typeInName = typeParts[0]
      let typeOutName = typeParts[1] || typeInName
      let attribute = parts[1] || name

      if (availableFieldsTypes[typeInName] && availableFieldsTypes[typeOutName]) {
        prepareFields[name] = { in: availableFieldsTypes[typeInName][FILTER_IN], out: availableFieldsTypes[typeOutName][FILTER_OUT], attribute }
      }

    } else {

      prepareFields[name] = fields[name]

    }

  })

  return prepareFields
}

export function filterFields(attributes, fields, direction) {

  const preparedFields = prepareFields(fields)
  const filteredAttributes = cloneDeep(attributes)

  Object.keys(preparedFields).forEach(name => {
    if (preparedFields[name][direction]) {
      let attribute = preparedFields[name].attribute || name
      filteredAttributes[name] = preparedFields[name][direction](attributes[attribute], attributes)
    }
  })

  return filteredAttributes
}


export const availableRuleOperators = {
  'lt': { operator: 'lt', value: value => value },
  'gt': { operator: 'gt', value: value => value },
  'lte': { operator: 'lte', value: value => value },
  'gte': { operator: 'gte', value: value => value },
  'eq': { operator: 'eq', value: value => value },
  'neq': { operator: 'neq', value: value => value },
  'in': { operator: 'in', value: value => value },
  'nin': { operator: 'nin', value: value => value },
  'like': { operator: 'like', value: value => value },
  'query': { operator: 'query', value: value => String(value).split(' ') },
}

function prepareRules(rules) {

  const preparedRules = {}

  Object.keys(rules).forEach(name => {

    preparedRules[name] = []
    
    let _rules = !isArray(rules[name]) ? [rules[name]] : rules[name]

    _rules.forEach(rule => {

      if (isString(rule)) {

        let hasMark = rule.slice(-1) === '!'
        let parts = (hasMark ? rule.slice(0, -1) : rule).split(':')
        let ruleOperator = parts[0]
        let ruleField = parts[1] || name

        if (availableRuleOperators[ruleOperator]) {
          preparedRules[name].push(merge({ field: ruleField, skipOnEmpty: !hasMark }, availableRuleOperators[ruleOperator]))
        }

      } else{

        preparedRules[name].push(merge({ operator: 'eq', value: value => value, field: name, skipOnEmpty: true }, rule))

      }

    })
  })

  return preparedRules
}

export function filterRules(attributes, rules) {

  const preparedRules = prepareRules(rules)
  const filteredAttributes = {}

  const filteredAnd = {}
  let filteredAndIndex = 0

  Object.keys(attributes).forEach(name => {

    if (preparedRules[name] && isArray(preparedRules[name])) {

      preparedRules[name].forEach(rule => {

        if (rule.operator === 'query' && (!utils.isEmpty(attributes[name]) || !rule.skipOnEmpty)) {

          // rule: {field: "contact_name,company_name,address,postcode,city,email,phone_number,mobile_number", skipOnEmpty: true, operator: "query", value: ƒ}

          // and: {
          //   0: { or: [
          //     { contact_name: { like: 'test' }},
          //     { address: { like: 'test' }},
          //   ]},
          //   1: { or: [
          //     { contact_name: { like: 'straat' }},
          //     { address: { like: 'straat' }},
          //   ]},
          // },

          let values = rule.value(attributes[name]) || []
          let fields = rule.field.split(',') || []

          if (fields.length > 1) {
            values.forEach(value => {
              if (value) {
                let filterOr = []

                fields.forEach(field => {

                  let filter = {}
                  filter[field] = { like: value }
                  filterOr.push(filter)

                })

                filteredAnd[filteredAndIndex++] = { or: filterOr }
              }
            })
          } else {

            if (!filteredAttributes[rule.field]) {
              filteredAttributes[rule.field] = {}
            }
  
            filteredAttributes[rule.field]['like'] = values

          }

        } else {

          if (!filteredAttributes[rule.field]) {
            filteredAttributes[rule.field] = {}
          }

          if (!utils.isEmpty(attributes[name]) || !rule.skipOnEmpty) {
            filteredAttributes[rule.field][rule.operator] = rule.value(attributes[name])
          }
        }
      })

    } else {

      if (!filteredAttributes[name]) {
        filteredAttributes[name] = {}
      }

      if (!utils.isEmpty(attributes[name])) {
        filteredAttributes[name]['eq'] = attributes[name]
      }

    }
  })

  if (Object.keys(filteredAnd).length) {
    filteredAttributes['and'] = filteredAnd
  }

  return filteredAttributes
}


