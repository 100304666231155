import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: () => ({ name: 'offers'}),
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/Dashboard.vue')
  },
  /* relations */
  {
    path: '/klanten',
    name: 'customers',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/relations/Customers.vue')
  },
  {
    path: '/klanten/:id',
    name: 'customer',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/relations/Customer.vue')
  },
  {
    path: '/leverancier',
    name: 'suppliers',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/relations/Suppliers.vue')
  },
  /* sales */
  {
    path: '/offertes',
    name: 'offers',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/Offers.vue')
  },
  {
    path: '/offerte/:id',
    name: 'offer',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/Offer.vue')
  },
  {
    path: '/offerte/regel/:id',
    name: 'offer-line',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/OfferLine.vue')
  },
  {
    path: '/offerte/:offer_id/regel/:offer_line_type_id',
    name: 'offer-line-new',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/OfferLine.vue')
  },
  {
    path: '/artikelen',
    name: 'products',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/Products.vue')
  },
  {
    path: '/artikelgroepen',
    name: 'product-groups',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/ProductGroups.vue')
  },
  {
    path: '/systemen',
    name: 'systems',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/Systems.vue')
  },
  {
    path: '/staffelprijzen',
    name: 'tier-prices',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/TierPrices.vue')
  },
  {
    path: '/factureren',
    name: 'billing',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/sales/Billing.vue')
  },
  /* inkoop */
  {
    path: '/in-te-kopen-artikelen',
    name: 'buy',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/purchasing/Buy.vue')
  },
  {
    path: '/bestellingen-overzicht',
    name: 'purchasing-overview',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/purchasing/Overview.vue')
  },
  {
    path: '/binnenkomst-goederen',
    name: 'arrival-of-goods',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/purchasing/ArrivalOfGoods.vue')
  },
  /* montage */
  {
    path: '/inplannen',
    name: 'assembly-schedule',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/assembly/Schedule.vue')
  },
  {
    path: '/uitgevoerd-melden',
    name: 'assembly-todo',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/assembly/Todo.vue')
  },
  /* settings */
  {
    path: '/instellingen',
    name: 'settings',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/settings/Settings.vue')
  },
  {
    path: '/redenen-van-afwijzingen',
    name: 'reject-reasons',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/settings/RejectReasons.vue')
  },
  {
    path: '/mail-templates',
    name: 'mail-templates',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/settings/MailTemplates.vue')
  },
  {
    path: '/gebruikers',
    name: 'users',
    component: () => import(/* webpackChunkName: "offertool" */ '../views/settings/Users.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
