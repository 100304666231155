import '@fortawesome/fontawesome-pro/css/all.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import nl from 'vuetify/es5/locale/nl'


Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#006eb8',
        secondary: '00972d',
        accent: '#26b3fb',
        title: '#022e5f',
        'title-grey': colors.blueGrey.lighten2,
        background: '#eaeaea',
      },
      dark: {
        primary: '#006eb8',
        secondary: '00972d',
        accent: '#26b3fb',
        title: '#022e5f',
        'title-grey': colors.blueGrey.lighten2,
        background: '#eaeaea',
      },
    },
  },
  lang: {
    locales: { nl },
    current: 'nl',
  },
  icons: {
    iconfont: 'fa',
  }
});
