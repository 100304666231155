/* eslint-disable */
import Vue from 'vue'
import { reactive, watch, toRef, toRefs } from '@vue/composition-api'
import { filterFields, filterRules, FILTER_IN } from './filters'
import { createStorage } from './storage'
import { merge, isArray } from 'lodash'



export function createCollection(client, resource, options) {

  const state = reactive({
    items: [],
    filter: {},
    isSearching: false,

    options: { itemsPerPage: options.itemsPerPage },

    page: null,
    itemsPerPage: options.itemsPerPage,
    totalCount: null,
    sort: null,
  })

  options.storage = createStorage(options.storage || localStorage)
  options.store = options.store === true ? `${resource}_` + btoa(JSON.stringify(options.params)) : options.store

  options.params = options.params || {}
  options.fields = options.fields || {}
  options.rules = options.rules || {}

  function debug() {
    options.debug && console.log(resource, ...arguments)
  }

  if (options.store) {
    let _state = options.storage.getObject(options.store)

    if (_state && _state.options) {
      state.options = _state.options || state.options
      state.page = _state.options.page || state.page
      state.itemsPerPage = _state.options.itemsPerPage || state.itemsPerPage
      state.sort = _state.options.sort || state.sort
      state.filter = _state.filter || state.filter
    }
  }

  // vuetify integration (maybe decouple)
  watch(() => state.options, options => {
    state.page = options.page
    state.itemsPerPage = options.itemsPerPage
    const sortItems = []

    debug('options', options)

    if (isArray(options.sortBy)) {
      for (let index = 0; index < options.sortBy.length; ++index) {
        sortItems.push((options.sortDesc && options.sortDesc[index] ? '-' : '') + options.sortBy[index])
      }
    }

    state.sort = sortItems.length ? sortItems.join(',') : null

    search()
  })
  

  function fetchParams(params = {}) {

    const fetchedParams = merge({}, options.params)

    fetchedParams.filter = fetchedParams.filter || {}
    merge(fetchedParams.filter, filterRules(state.filter, options.rules))

    if (state.page) {
      fetchedParams.page = state.page
    }

    fetchedParams['per-page'] = state.itemsPerPage
    
    if (state.sort) {
      fetchedParams.sort = state.sort
    }

    merge(fetchedParams, params)
    
    return fetchedParams
  }

  function search(params = {}) {

    state.isSearching = true
    const request = { params: fetchParams(params) }

    debug('search', request)

    if (options.store) {
      options.storage.setObject(options.store, { filter: state.filter, options: state.options })
    }

    return client.get(resource, request)
      .then(response => {
        populate(response.data || [], response.headers || {})
        state.isSearching = false
        return Promise.resolve(response)
      })
      .catch(error => {
        state.items = []
        state.isSearching = 'error'
        return Promise.reject(error)
      })
  }

  function all(params = {}) {

    const request = { params: fetchParams(merge({ 'per-page': null }, params)) }

    debug('all', request)

    client.get(resource, request)
      .then(response => {
        populate(response.data || [], response.headers || {})
      })
      .catch(() => {
        state.items = []
      })

    return toRef(state, 'items')
  }

  function populate(items, headers = {}) {
    
    items.forEach((item, index) => {
      items[index] = filterFields(item, options.fields, FILTER_IN)
    })

    state.items = items

    state.page = headers['x-pagination-current-page'] ? Number(headers['x-pagination-current-page']) : state.page
    state.itemsPerPage = headers['x-pagination-per-page'] ? Number(headers['x-pagination-per-page']) : state.itemsPerPage
    state.totalCount = headers['x-pagination-total-count'] ? Number(headers['x-pagination-total-count']) : null
  }

  function setFilter(name, value) {
    state.filter[name] = value
  }

  function forEach(cb) {
    state.items.forEach(cb)
  }

  function _search(params = {}) {
    // don't trigger watch
    const _options = state.options
    _options.page = 1
    state.options = _options
    return search(params)
  }

  return {
    state,
    ...toRefs(state),
    search,
    _search,
    all,
    populate,
    setFilter,
    forEach,
  }


}