


export function createStorage(storage) {

  function setItem(key, value) {
    storage.setItem(key, value)
  }

  function getItem(key) {
    return storage.getItem(key)
  }

  function removeItem(key) {
    return storage.removeItem(key)
  }

  function setObject(key, object) {
    storage.setItem(key, JSON.stringify(object))
  }

  function getObject(key) {
    try { return JSON.parse(storage.getItem(key)) } catch(err) { console.log(err) }
    return null
  }

  return {
    setItem,
    getItem,
    removeItem,
    setObject,
    getObject,
  }

}