import Vue from 'vue'
import { reactive, computed, watch, toRefs } from '@vue/composition-api'
import { filterFields, FILTER_IN, FILTER_OUT } from './filters'
import { transform, isEqual, isObject, isArray, cloneDeep } from 'lodash'


export function convertErrors(errors) {
  const convertedErrors = {}

  if (Array.isArray(errors)) {
    errors.forEach(function(element) {
      if (element.field && element.message) {
        if (!convertedErrors[element.field]) {
          convertedErrors[element.field] = []
        }
        convertedErrors[element.field].push(element.message)
      }
    })
  }

  return convertedErrors
}



export function createModel(client, name, options) {

  const state = reactive({
    isNewRecord: computed(() => !Object.keys(state.oldAttributes).length),
    attributes: {},
    oldAttributes: {},
    errors: {},
    isFinding: false,
    isCreating: false,
    isUpdating: false,
    isSaving: false,
    isBusy: computed(() => state.isFinding || state.isCreating || state.isUpdating || state.isSaving),
  })

  options.params = options.params || {}
  options.primaryKey = options.primaryKey || ['id']
  options.fields = options.fields || {}

  function getPath(ids = []) {
    return name + (ids.length ? '/' + ids.join(',') : '')
  }

  function getPrimaryKey()
  {
    const values = []

    options.primaryKey.forEach(name => {
      values.push(state.attributes[name])
    })

    return values
  }

  function debug() {
    options.debug && console.log(getPath(!state.isNewRecord ? getPrimaryKey() : []), ...arguments)
  }

  function setResource(_name, id, suffix) {
    name = _name 
    
    if (id) {
      name += '/' + (Array.isArray(id) ? id.join(',') : id)
    }

    if (suffix) {
      name += '/' + suffix
    }
  }

  function onReady(cb) {
    if (state.isBusy) {
      const unWatchIsBusy = watch(() => state.isBusy, val => {
        if (!val) {
          cb(state.attributes)
          unWatchIsBusy()
        }
      })
    } else {
      cb(state.attributes)
    }
  }

  function find(id) {

    state.isFinding = true
    state.errors = {}

    clear()

    debug('find', id)

    const path = getPath(Array.isArray(id) ? id : [id])
    const request = { params: options.params }

    return client.get(path, request)
      .then(response => {
        populate(filterFields(response.data, options.fields, FILTER_IN))
        return Promise.resolve(response)
      })
      .catch(error => {
        return Promise.reject(error)
      })
      .finally(() => {
        state.isFinding = false
      })
  }

  function create(names = null) {

    state.isCreating = true
    state.errors = {}

    const path = getPath()
    const attributes = filterFields(getAttributes(names), options.fields, FILTER_OUT)
    const request = { params: options.params }

    debug('create', attributes)

    return client.post(path, attributes, request)
      .then(response => {
        populate(filterFields(response.data, options.fields, FILTER_IN), Object.keys(attributes))
        return Promise.resolve(response)
      })
      .catch(error => {
        if (error && error.response && error.response.status === 422) {
          state.errors = convertErrors(error.response.data)
        }
        return Promise.reject(error)
      })
      .finally(() => {
        state.isCreating = false
      })
  }

  function update(names = null) {

    state.isUpdating = true
    state.errors = {}

    const path = getPath(getPrimaryKey())
    const attributes = filterFields(getDirtyAttributes(names), options.fields, FILTER_OUT)
    const request = { params: options.params }

    debug('update', attributes)

    return client.put(path, attributes, request)
      .then(response => {
        populate(filterFields(response.data, options.fields, FILTER_IN), Object.keys(attributes))
        return Promise.resolve(response)
      })
      .catch(error => {
        if (error && error.response && error.response.status === 422) {
          state.errors = convertErrors(error.response.data)
        }
        return Promise.reject(error)
      })
      .finally(() => {
        state.isUpdating = false
      })
  }

  function save(names = null) {

    state.isSaving = true

    debug('save')

    return (state.isNewRecord ? create(names) : update(names)).finally(() => {
      state.isSaving = false
    })
  }

  function touch(names = null) {

    state.isTouching = true

    const path = getPath(getPrimaryKey())
    const attributes = filterFields(getAttributes(names), options.fields, FILTER_OUT)
    const request = { params: options.params }

    debug('touch', attributes)

    return client.put(path, attributes, request)
      .then(response => {
        populate(filterFields(response.data, options.fields, FILTER_IN), Object.keys(attributes))
        return Promise.resolve(response)
      })
      .catch(error => {
        debug('error', error)
        return Promise.reject(error)
      })
      .finally(() => {
        state.isTouching = false
      })

  }


  /**
   * Returns the deep difference between two objects
   * @param {*} object 
   * @param {*} base 
   * @returns array with difference
   */
  function difference(object, base) {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) && !isArray(value) ? difference(value, base[key]) : value
      }
    })
  }

  /**
   * Returns attribute values.
   * @param array names list of attributes whose value needs to be returned.
   * Defaults to null, meaning all attributes listed in [[attributes()]] will be returned.
   * If it is an array, only the attributes in the array will be returned.
   * @param array except list of attributes whose value should NOT be returned.
   * @return array attribute values (name => value).
   */
  function getAttributes(names = null, except = []) {

    names = Array.isArray(names)
      ? Object.keys(state.attributes).filter(name => names.includes(name) && !except.includes(name))
      : Object.keys(state.attributes).filter(name => !except.includes(name))

    const attributes = {}

    names.forEach(name => {
      attributes[name] = cloneDeep(state.attributes[name])
    })

    return attributes
  }

  /**
   * Returns the attribute values that have been modified since they are loaded or saved most recently.
   * @param {string[]|null} names the names of the attributes whose values may be returned if they are
   * changed recently. If null or true, [[attributes()]] will be used.
   * @return {array} the changed attribute values (name-value pairs)
   */  
  function getDirtyAttributes(names = null) {

    names = Array.isArray(names)
      ? Object.keys(state.attributes).filter(name => names.includes(name))
      : Object.keys(state.attributes)

    const dirtyAttributes = difference(state.attributes, state.oldAttributes)
    const dirtyNames = Object.keys(dirtyAttributes)
    const attributes = {}

    names.forEach(name => {
      if (dirtyNames.includes(name)) {
        attributes[name] = cloneDeep(dirtyAttributes[name])
      }
    })

    return attributes
  }

  /**
   * Populates current model with given attributes
   * @param {*} attributes 
   * @param {array|null} names the attribute names that are allowed. null means all
   */

  function populate(attributes, names = null) {

    const dirtyAttributes = getDirtyAttributes()
    const dirtyNames = Object.keys(dirtyAttributes)

    names = Array.isArray(names)
      ? Object.keys(attributes).filter(name => names.includes(name))
      : Object.keys(attributes)

    Object.keys(attributes).forEach(name => {
      if (names.includes(name) || !dirtyNames.includes(name)) {
        Vue.set(state.attributes, name, cloneDeep(attributes[name]))
      }
      Vue.set(state.oldAttributes, name, cloneDeep(attributes[name]))
    })
  }

  /**
   * Refreshes the current model
   * @returns Promise
   */

  function refresh() {

    debug('refresh')

    return find(getPrimaryKey())
  }

  /**
   * Resets current model with old attributes
   * @param {array|null} names the attribute names to be reset. null means all
   */
  function reset(names = null) {

    names = Array.isArray(names)
      ? Object.keys(state.attributes).filter(name => names.includes(name))
      : Object.keys(state.attributes)

    debug('reset', names)

    const oldNames = Object.keys(state.oldAttributes)

    names.forEach(name => {
      Vue.set(state.attributes, name, oldNames.includes(name) ? cloneDeep(state.oldAttributes[name]) : null)
    })

    state.errors = {}
  }


  /**
   * Clears the current state of the model
   */
  function clear() {

    debug('clear')

    state.attributes = {}
    state.errors = {}
    state.oldAttributes = {}
  }

  function setAttribute(name, value) {
    Vue.set(state.attributes, name, value)
  }

  function setAttributes(attributes) {
    Object.keys(attributes).forEach(name => {
      setAttribute(name, attributes[name])
    })
  }

  function getAttribute(name, defaultValue = null) {
    return state.attributes[name] || defaultValue
  }

  function getOldAttribute(name, defaultValue = null) {
    return state.oldAttributes[name] || defaultValue
  }

  // @param names string|array
  function isDirtyAttribute(names) {
    const dirtyAttributes = difference(state.attributes, state.oldAttributes)

    if (!Array.isArray(names)) {
      names = [names]
    }

    return !!Object.keys(dirtyAttributes).filter(name => names.includes(name)).length

    // return Object.keys(dirtyAttributes).includes(name)
  }


  return {
    state,
    ...toRefs(state),
    find,
    create,
    update,
    save,
    touch,
    populate,
    refresh,
    reset,
    clear,
    setAttribute,
    setAttributes,
    getAttribute,
    getAttributes,
    getOldAttribute,
    isDirtyAttribute,
    getDirtyAttributes,
    onReady,
    setResource,
  }


  
}